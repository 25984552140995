import {abstractFilter} from './abstractFilter';

function abstractDefaultFilter() {
    throw new Error('lib-filter: abstractDefaultFilter is abstract');
}

var P = abstractDefaultFilter.leu_extend(abstractFilter).prototype;

P.is_default = function () {
    return true;
};

P.apply_filter = function () {
    throw new Error('lib-filter: descedants of abstractDefaultFilter are default and use get_default() instad of apply_filter()');
};



export {abstractDefaultFilter};